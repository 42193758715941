exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-book-loader-js": () => import("./../../../src/pages/book-loader.js" /* webpackChunkName: "component---src-pages-book-loader-js" */),
  "component---src-pages-dashboard-js": () => import("./../../../src/pages/dashboard.js" /* webpackChunkName: "component---src-pages-dashboard-js" */),
  "component---src-pages-fish-tracker-js": () => import("./../../../src/pages/fish-tracker.js" /* webpackChunkName: "component---src-pages-fish-tracker-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-robot-js": () => import("./../../../src/pages/robot.js" /* webpackChunkName: "component---src-pages-robot-js" */),
  "component---src-pages-sesh-stats-js": () => import("./../../../src/pages/sesh-stats.js" /* webpackChunkName: "component---src-pages-sesh-stats-js" */)
}

